import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAQ0veazWtkyuwcOXj7tHhyoiByLDx-zeo",
  authDomain: "react-portfolio-712a3.firebaseapp.com",
  projectId: "react-portfolio-712a3",
  storageBucket: "react-portfolio-712a3.appspot.com",
  messagingSenderId: "734324577713",
  appId: "1:734324577713:web:f107a5e06b775665d95990",
  measurementId: "G-PGN7X00YHF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);