import './css/Message.css';
import { send } from 'emailjs-com';
import * as All from './importList';
import { gsap } from "gsap";


function Message() {
    const [firstName, setFirstName] = All.React.useState('');
    const [lastName, setLastName] = All.React.useState('');
    const [email, setEmail] = All.React.useState('');
    const [phone, setPhone] = All.React.useState('');
    const [message, setMessage] = All.React.useState('');
    const [radiobtn, setRadioBtn] = All.React.useState('');
    const [loading, setLoading] = All.React.useState(false);
    const [theme, setTheme] = All.React.useState('');
    const [toSend, setToSend] = All.React.useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
        kebutuhan: '',
        no_telp: '',
    });
    const [alert, setAlert] = All.React.useState(false);
    const [formAlert, setFormAlert] = All.React.useState(false);

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
        // console.log(firstName);
    };
    const handleLastName = (event) => {
        setLastName(event.target.value);
        // console.log(lastName);
    };
    const handleEmail = (event) => {
        setEmail(event.target.value);
        // console.log(email);
    };
    const handlePhone = (event) => {
        setPhone(event.target.value);
        // console.log(phone);
    };
    const handleMessage = (event) => {
        setMessage(event.target.value);
        // console.log(message);
    };
    const handleRadioBTN = (event) => {
        setRadioBtn(event.target.value);
        // console.log(event.target.value);
    }
    All.React.useEffect(() => {
        
        if (loading) {
            setToSend({
                from_name: firstName + " " + lastName,
                to_name: 'Aries Firmansyah',
                message: message,
                reply_to: email,
                kebutuhan: radiobtn,
                no_telp: phone,
            });
            setTimeout( () =>{
                setLoading(false);
            }, 2000);
            
            
        } else {
            if(toSend.from_name !== '') {
                if(firstName !== '' && lastName !== '' && email !== '' && phone !== ''
                && radiobtn !== '' && message !== '') {
                    send( 'service_moh5w58', 'template_wxb2koj', toSend, 'user_boedDjkVAVQDyaPYthtYK')
                        .then((response) => {
                            // console.log('SUCCESS!', response.status, response.text);
                            setAlert(true);
                            setTimeout( () =>{
                                setAlert(false);
                            }, 5000);
                        })
                        .catch((err) => {
                            console.log('FAILED...', err);
                        });
                    
                } else {
                    setFormAlert(true);
                    setTimeout( () =>{
                        setFormAlert(false);
                    }, 5000);
                }
            }
            setToSend({
                from_name: '',
                to_name: '',
                message: '',
                reply_to: '',
                kebutuhan: '',
                no_telp: '',
            }); 
        }
    }, [loading]);

    All.React.useEffect(() => {
        if (sessionStorage.getItem('mode') === null)  {
            setTheme("true");
        } else {

            setTheme(sessionStorage.getItem('mode'));
        }
    });
    
    All.React.useEffect(() => {
        gsap.to((".contact"), { rotation: "+=360" });
    }, []);

  return (
    <div className="message-container1 fade-in">
        <br></br>
        <All.Container>
            <All.Collapse in={alert}>
                <All.Alert
                action={
                    <All.IconButton aria-label="close" color="inherit" size="small"
                    onClick={() => {setAlert(false);} } >
                    <All.CloseIcon fontSize="inherit" />
                    </All.IconButton>
                }
                sx={{ mb: 2, maxWidth: 190, position: 'absolute' }}>
                    Message sent
                </All.Alert>
            </All.Collapse>
            <All.Collapse in={formAlert}>
                <All.Alert severity="error"
                action={
                    <All.IconButton aria-label="close" color="inherit" size="small"
                    onClick={() => {setFormAlert(false);} } >
                    <All.CloseIcon fontSize="inherit" />
                    </All.IconButton>
                }
                sx={{ mb: 2, maxWidth: 390, position: 'absolute' }}>
                    Please fill in all the fields on the form!
                </All.Alert>
            </All.Collapse>
            <All.Container maxWidth="sm">
                <div className="message-container2">
                    <h1 style={{color: '#166FAF'}} className="contact">Contact</h1>
                    <p style={{color: theme === "true"  ? '#E0E0E0' : 'black'}} data-aos="fade-up">
                        Any question or remarks? Just wrrite a message!
                    </p>
                </div>
            </All.Container>
            <div className={theme === "true" ? "input-container color1" : "input-container color2"} >
                <All.Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1} >
                    <All.Grid item md={5} sm={5} data-aos="fade-right">
                        <div className="contact-information">
                            <h3 style={{paddingTop: 40}}>Contact Information</h3>
                            <p style={{fontSize: 13}}>Fill up the form and i will get back to you within 2x24 hours.</p>
                            <div style={{display: 'flex', paddingTop: 10}}>
                                <img src={All.PhoneCall} style={{width: '30px', height: '30px', marginTop: 17}} />
                                <h5 style={{marginLeft: 10}}>+628 965 619 5418</h5>
                            </div>
                            <div style={{display: 'flex', paddingTop: 10}}>
                                <img src={All.Gmail} style={{width: '30px', height: '30px', marginTop: 17}} />
                                <h5 style={{marginLeft: 10}}>ariesfirmansyah678@gmail.com</h5>
                            </div>
                            <div style={{display: 'flex', paddingTop: 10, paddingBottom: 10}}>
                                <img src={All.Location} style={{width: '30px', height: '30px', marginTop: 17}} />
                                <h5 style={{marginLeft: 10}}>Banten</h5>
                            </div>
                        </div>
                    </All.Grid>
                    <All.Grid item md={7} sm={7} className="fade-in">
                        <div className="input-padding" >
                            <All.Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                <All.Grid item md={6} xs={6} >
                                    <All.FormControl variant="standard" style={{marginTop: 10}}>
                                        <All.InputLabel htmlFor="component-simple">First Name</All.InputLabel>
                                        <All.Input id="component-simple" placeholder="First Name" onChange={handleFirstName} />
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={6} xs={6}>
                                    <All.FormControl variant="standard"  style={{marginTop: 10}}>
                                        <All.InputLabel htmlFor="component-simple">Last Name</All.InputLabel>
                                        <All.Input id="component-simple" placeholder="Last Name" onChange={handleLastName} />
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={6} xs={6}>
                                    <All.FormControl variant="standard" style={{marginTop: 10}}>
                                        <All.InputLabel htmlFor="component-simple">Email</All.InputLabel>
                                        <All.Input id="component-simple" placeholder="Email" onChange={handleEmail} />
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={6} xs={6}>
                                    <All.FormControl variant="standard" style={{marginTop: 10}}>
                                        <All.InputLabel htmlFor="component-simple">Phone</All.InputLabel>
                                        <All.Input id="component-simple" placeholder="Phone" onChange={handlePhone} />
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={12}>
                                    <All.FormControl component="fieldset" style={{marginTop: 13}}>
                                        <All.FormLabel component="legend">What the of website do you need?</All.FormLabel>
                                        <All.RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleRadioBTN} >
                                            <All.FormControlLabel sx={{color: 'black'}} value="Web Development" control={<All.Radio />} label="Web Development" />
                                            <All.FormControlLabel sx={{color: 'black'}}  value="Web Design" control={<All.Radio />} label="Web Design" />
                                            <All.FormControlLabel sx={{color: 'black'}}  value="Database Administrator" control={<All.Radio />} label="Database Administrator" />
                                            <All.FormControlLabel sx={{color: 'black'}}  value="other" control={<All.Radio />} label="Other" />
                                        </All.RadioGroup>
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={7} sm={7} xs={12}>
                                    <All.FormControl variant="standard" style={{marginTop: 10}}>
                                        <All.InputLabel htmlFor="component-simple">Message</All.InputLabel>
                                        <All.Input id="component-simple" placeholder="Write your message.." onChange={handleMessage} />
                                    </All.FormControl>
                                </All.Grid>
                                <All.Grid item md={5} sm={5} xs={7} style={{marginTop: 20}}>
                                    <All.LoadingButton size="small" variant="contained" color="secondary" endIcon={<All.SendIcon />}
                                    loading={loading} onClick={() => setLoading(true)}
                                    loadingPosition="end">
                                        Send Message
                                    </All.LoadingButton>
                                </All.Grid>
                            </All.Grid>
                        </div>
                    </All.Grid>
                </All.Grid>
            </div>
        </All.Container>
    </div>
  );
}

export default Message;
