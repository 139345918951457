import './css/Projects.css';
import * as All from './importList';

function Projects() {

    const [value, setValue] = All.React.useState('1');
    const [theme, setTheme] = All.React.useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const ReactIcon = () => (
        <img src={All.React1} style={{width: 40}}/>
    );
    const LaravelIcon = () => (
        <img src={All.Laravel} style={{width: 40}}/>
    );
    const AngularIcon = () => (
        <img src={All.Angular} style={{width: 60}}/>
    );
    const CodeIginiter = () => (
        <img src={All.CI} style={{width: 50}}/>
    );
    const OtherIcon = () => (
        <img src={All.Other} style={{width: 40.6}}/>
    );
    All.React.useEffect(() => {
        if (sessionStorage.getItem('obscura') === "true") {
            setValue('5');
            sessionStorage.setItem('obscura', false);
        }else if (sessionStorage.getItem('mentoring') === "true") {
            setValue('4');
            sessionStorage.setItem('mentoring', false);
        }
        if (sessionStorage.getItem('mode') === null)  {
            setTheme("true");
        } else {
            setTheme(sessionStorage.getItem('mode'));
        }
    });

    return (
        <div className="fade-in">
            <All.Container maxWidth="md">
                <h1 data-aos="fade-right">My Projects</h1>
                <p style={{textAlign: 'justify', paddingBottom: 40}} data-aos="fade-up">
                    Sometimes it is difficult to work on a project such as a website without a 
                    framework or using a framework such as Laravel, React JS, and others. 
                    However, when we finish working on a project, we sometimes find that the 
                    project we are working on is not difficult to do. Here are some projects 
                    that I have worked on.
                </p>

                <All.Grid spacing={1}>
                    <All.Grid item>
                        <All.TabContext value={value}>
                            <All.Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                <All.TabList onChange={handleChange} aria-label="lab API tabs example"
                                    variant="scrollable"
                                    crollButtons
                                    allowScrollButtonsMobile data-aos="fade-left">
                                    <All.Tab icon={<ReactIcon />} label={theme === "true"  ? 
                                        <p style={{margin: 0, marginTop: 7, color: 'white', fontWeight: 'bold'}}>
                                            React
                                        </p> : 
                                        <p style={{margin: 0, marginTop: 7, fontWeight: 'bold'}}>
                                            React
                                        </p> }
                                        value="1"/>
                                    <All.Tab icon={<LaravelIcon />} label={theme === "true"  ? 
                                        <p style={{margin: 0, marginTop: 7, color: 'white', fontWeight: 'bold'}}>
                                            Laravel
                                        </p> : 
                                        <p style={{margin: 0, marginTop: 7, fontWeight: 'bold'}}>
                                            Laravel
                                        </p> }
                                        value="2" />
                                    <All.Tab icon={<AngularIcon />} label={theme === "true"  ? 
                                        <p style={{margin: 0, marginTop: 7, color: 'white', fontWeight: 'bold'}}>
                                            Angular
                                        </p> : 
                                        <p style={{margin: 0, marginTop: 7, fontWeight: 'bold'}}>
                                            Angular
                                        </p> }
                                        value="3" />
                                    <All.Tab icon={<CodeIginiter />} label={theme === "true"  ? 
                                        <p style={{margin: 0, marginTop: 7, color: 'white', fontWeight: 'bold'}}>
                                            CodeIginiter
                                        </p> : 
                                        <p style={{margin: 0, marginTop: 7, fontWeight: 'bold'}}>
                                            CodeIginiter
                                        </p> }
                                        value="4" />
                                    <All.Tab icon={<OtherIcon />} label={theme === "true"  ? 
                                        <p style={{margin: 0, marginTop: 7, color: 'white', fontWeight: 'bold'}}>
                                            Other
                                        </p> : 
                                        <p style={{margin: 0, marginTop: 7, fontWeight: 'bold'}}>
                                            Other
                                        </p> }
                                        value="5" />
                                </All.TabList>
                            </All.Grid>
                            <div>
                                <All.TabPanel value="1" >
                                    <All.ProjectList gambar={All.R4} judul="MovieDB (MERN Stack)" 
                                        caption="This website contains a information of movie that you want. 
                                        You can also search a movie that you want. You can make an account to add your favorite
                                        movies to your account" 
                                        width={200} mt={0}
                                        link="https://ariesfirmansyah-risdevmovie.vercel.app/" github="https://github.com/AriesFirmansyah/MovieDB" />
                                    <All.ProjectList gambar={All.R2} judul="Social Media (DummyAPI)" 
                                        caption="This social media website contains a post from someone using DummyAPI data. 
                                        This website also displays comments from someone in a post and can see the profile of 
                                        that person." 
                                        width={200} mt={0}
                                        link="https://project-sosial-media.ariesfirmansyah.vercel.app/" github="https://github.com/AriesFirmansyah/Project-SosialMedia" />
                                    <All.ProjectList gambar={All.R1} judul="Converter Website" 
                                        caption="Websites that can convert several available units such as time, speed, weight, and temperature." 
                                        width={200} mt={0}
                                        link="https://project-convert-git-main-ariesfirmansyah.vercel.app/" github="https://github.com/AriesFirmansyah/Project-Convert"/>
                                   
                                    <All.ProjectList gambar={All.R3} judul="Countdown Website" caption="Website for time countdown like a timer." 
                                        margin={90} width={200} mt={0}
                                        link="https://project-countdown-git-main-ariesfirmansyah.vercel.app/" github="https://github.com/AriesFirmansyah/Project-Countdown"  />

                                </All.TabPanel>
                                <All.TabPanel value="2">
                                    <All.ProjectList gambar={All.L1} judul="Pandawa Restaurant" 
                                    caption="A website restaurant that provides several food and beverage menus. 
                                    You need to register/login in advance to be able to order the available menus. 
                                    The admin of Pandawa Restaurant will update the list of orders available through the 
                                    admin menu." 
                                    width={200} mt={0} link="http://pandawaresto.000webhostapp.com/" github="https://github.com/AriesFirmansyah/Pandawa-Restaurant" />
                                    <All.ProjectList gambar={All.L2} judul="Azwatech Hotel Reservation" 
                                    caption="Website that provides hotel ticket booking services. Users can search for 
                                    the hotel they want to book based on location, room rate, or rating." 
                                    width={200} mt={0} link="https://azwatech2.000webhostapp.com/" github="https://github.com/AriesFirmansyah/Azwatech-Hotel-Reservation" />
                                    <All.ProjectList gambar={All.L3} judul="Azwatech Baby Gear" 
                                    caption="Websites that provide baby equipment such as toys, and so on. Click visit for more details." 
                                    margin={90} width={200} pd={50} mt={0} link="https://azwatech.000webhostapp.com/"
                                    github="https://github.com/AriesFirmansyah/Azwatech-Baby-Gear" />
                                </All.TabPanel>
                                <All.TabPanel value="3">
                                    <All.ProjectList gambar={All.A1} judul="Rismed (DummyAPI)" 
                                    caption="This social media website contains a post from someone using DummyAPI data. 
                                    This website also displays comments from someone in a post and can see the profile of 
                                    that person. This website is built using the Angular framework." 
                                    width={200} mt={0} pd={125}
                                    link="https://project-rismed.vercel.app/" github="https://github.com/AriesFirmansyah/Project-Rismed" />
                                </All.TabPanel>
                                <All.TabPanel value="4">
                                    <All.ProjectList gambar={All.CI1} judul="Mentoring UMN 2021" 
                                    caption="This website displays information about a mandatory 
                                    activity at Multimedia Nusantara University." 
                                    width={170} mt={-10} pd={95}
                                    link="https://mentoring.umn.ac.id/" github="https://github.com/AriesFirmansyah/Mentoring-2021" />
                                </All.TabPanel>
                                <All.TabPanel value="5">
                                    
                                    <All.ProjectList gambar={All.O3} judul="Pandawa Money Tracker" 
                                    caption="An apps that manage your daily finance. You can login as a user 
                                    and you can list your goals or your payment history. You can see a report 
                                    of all your payment and goals in report page." 
                                    width={170} mt={-12} link="https://github.com/AriesFirmansyah/PandawaMoneyTracker" github="https://github.com/AriesFirmansyah/PandawaMoneyTracker" />

                                    <All.ProjectList gambar={All.O1} judul="Obscura UMN 2020" 
                                    caption="A website that is used as data visualization from photography participants 
                                    through an organization called Obscura at Multimedia Nusantara University." 
                                    width={170} mt={-12} link="https://obscuraexhibition.net/" github="https://github.com/AriesFirmansyah/Obscura" />

                                    <All.ProjectList gambar={All.O2} judul="Jajan Dong (Line Front-End Framework)" 
                                    caption="A website that can reply to messages automatically when a user orders an 
                                    available menu. Users are required to use the LINE application and login first to be 
                                    able to order and receive messages from their orders. This website was created based on 
                                    lessons learned from Dicoding Indonesia." 
                                    width={170} mt={0} link="https://liff.line.me/1655527783-OpGgorY5" github="https://github.com/AriesFirmansyah/Line-Front-End-Framework" 
                                    pd={125}/>
                                </All.TabPanel>
                            </div>
                        </All.TabContext>
                    </All.Grid>
                </All.Grid>
            </All.Container>
        </div>
    );
}

export default Projects;
