import "./css/HomeContent.css"
import * as All from "./importList"
// import { motion } from "framer-motion";
import { gsap } from "gsap"

function HomeContent() {
  All.React.useEffect(() => {
    gsap.to(".grid-header", { rotation: "+=360" })
  }, [])

  return (
    <div className="center1 fade-in">
      <All.Container maxWidth="sm" className="container-1">
        <All.Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={0}>
          <All.Grid item className="grid-header">
            <h1>
              HELLO
              <span className="color4">.</span>
            </h1>
          </All.Grid>
          <All.Grid item>
            <h1 className="grid-name center">I'm Aries Firmansyah</h1>
          </All.Grid>
        </All.Grid>
        <All.Grid container direction="row" justifyContent="center" alignItems="center" className="container-2">
          <All.Grid xs={5}>
            <img src={All.Avatar} className="avatar-image no-drag" data-aos="fade-right" alt="avatar" />
          </All.Grid>
          <All.Grid xs={7}>
            <div className="text1">
              <h4 data-aos="fade-left">Currently working as a front-end web developer. You can see some of the projects I've worked on the projects page.</h4>
            </div>
          </All.Grid>
        </All.Grid>
      </All.Container>
    </div>
  )
}

export default HomeContent
