import './css/Projects.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';

function ProjectList(props) {
  return (
    <div style={{
        backgroundColor: '',
        marginTop: 30,
        marginBottom: props.margin,
        // borderBottom: '1px solid #C6C6C6'
        }} className="fade-in">
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
        style={{paddingBottom: 40, paddingBottom: props.pd}}>
            <Grid item md={4} sm={4} xs={12} style={{backgroundColor: '', justifyContent: 'center'}}> 
                <img  className="project-image" src={props.gambar} 
                style={{maxWidth: props.width, marginTop: props.mt}}  
                />
            </Grid>
            <Grid item md={8} sm={8} xs={12} style={{marginTop: -25}}> 
                <h2 className="judul-project">{props.judul}</h2>
                <p style={{wordBreak: 'break-word',
                    textAlign: 'justify'}}>
                    {props.caption}
                </p>
                <div className="project-button">
                    <Button href={props.link} target="_blank"
                    variant="contained" size="small" startIcon={<LaunchIcon />}>Visit</Button>
                    <Button href={props.github} target="_blank"
                    variant="contained" size="small" color="secondary" startIcon={<GitHubIcon />} style={{marginLeft: 10}}>
                        Repository
                    </Button>
                </div>
            </Grid>
        </Grid>
    </div>
  );
}

export default ProjectList;
