import './css/Navigation.css';
import * as All from './importList';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TagManager from 'react-gtm-module';
AOS.init();


const MaterialUISwitch = All.styled(All.Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32, 
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));


export default function Navigation() {
  const [checked, setChecked] = All.React.useState(true);
  All.React.useEffect(() => {
      if (sessionStorage.length !== 0) {
        sessionStorage.getItem('mode') === "true" ? setChecked(true) : setChecked(false);
      }
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    sessionStorage.setItem('mode', event.target.checked);
  };

  All.React.useEffect(() => {
    const tagManagerArgs = {
        // dataLayer: {
        //     page: '/pagepath'
        // },
        gtmId: 'GTM-PG82LVV',
        
    }
    TagManager.initialize(tagManagerArgs);

    window.dataLayer.push({
      event: 'event',
      eventProps: {
          category: "tescat",
          action: "action",
          label: "label",
          value: "value"
      }
    });
    

    // TagManager.initialize({
    //   gtmId: 'GTM-PG82LVV'
    // });

    
  }, []);
  return (
    <div 
    className={window.location.pathname === '/' ||window.location.pathname === '/profile'
      || window.location.pathname === '/message' 
      ? 'overflow' : ''}
      >
        
      <div className={checked  ? "bg-bodyDark" : "bg-bodyLight"} style={{paddingTop: '20px'}}>
          <All.Container maxWidth="sm">
              <All.Grid container spacing={2}>
                  <All.Grid container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start">
                    <All.Grid item>
                      <All.FormGroup className="fade-in" data-aos="fade-up-left">
                          <All.FormControlLabel
                              control={<MaterialUISwitch sx={{ m: 0 }} checked={checked} />}
                              label={checked ? <h5 style={{marginRight: -18}}>Dark</h5> : 
                              <h5 style={{marginRight: -18}}>Light</h5> } onChange={handleChange}/>
                      </All.FormGroup>
                    </All.Grid>
                  </All.Grid>
                  <All.Grid container direction="row" justifyContent="center" alignItems="center">
                      <div className={checked ? "bg-navigation-dark" : "bg-navigation-light" }>
                          <All.Grid container spacing={2} justifyContent="center">
                            <All.Grid item xs={2}>
                              <All.Link href="/projects">
                                <img src={All.ProjectImage} className="navigation-image margin-navigation" />
                              </All.Link>
                            </All.Grid>
                            <All.Grid item xs={2}>
                              <All.Link href="/experiences">
                                <img src={All.ExperienceImage} className="navigation-image margin-navigation" />
                              </All.Link>
                            </All.Grid>
                            <All.Grid item xs={2}>
                              <All.Link href="/">
                                <img src={All.HomeImage} className="navigation-image margin-navigation" />
                              </All.Link>
                            </All.Grid>
                            <All.Grid item xs={2}>
                              <All.Link href="/message">
                                <img src={All.MessageImage} className="navigation-imageMessage margin-navigation" />
                              </All.Link>
                            </All.Grid>
                            <All.Grid item xs={2} >
                              <All.Link href="/profile">
                                <img src={All.ProfileImage} className="navigation-image" />
                              </All.Link>
                            </All.Grid>
                          </All.Grid>
                      </div>
                  </All.Grid>
                  {/* <All.Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <div style={{position: 'fixed', bottom: 0}}>
                      <All.Grid container spacing={2} justifyContent="center">
                        <All.Grid item md={2} style={{marginRight: '10px'}}>
                          <All.Link href="/projects">
                            <img src={All.InstagramImage} 
                              className={window.location.pathname === '/profile' ? "hide" : "navigation-image2"} />
                          </All.Link>
                        </All.Grid>
                        <All.Grid item md={2} style={{marginRight: '10px'}}>
                          <All.Link href="/experiences">
                            <img src={All.LinkedinImage} 
                            className={window.location.pathname === '/profile' ? "hide" : "navigation-image2"} />
                          </All.Link>
                        </All.Grid>
                      </All.Grid>
                    </div>
                  </All.Grid> */}
              </All.Grid>
              
          </All.Container>  
          <All.Router>
            <All.HomeContent path="/"></All.HomeContent>
            <All.Profile path="/profile"></All.Profile>
            <All.Message path="/message"></All.Message>
            <All.Projects path="/projects"></All.Projects>
            <All.Experiences path="/experiences"></All.Experiences>
          </All.Router>  
      </div>
    </div>
    
  );
}
