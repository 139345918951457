import './css/Profile.css';
import * as All from './importList';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { db } from "./firebase/firebase-config";
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";


const ColorButton = All.styled(All.Button)(({ theme }) => ({
    color: theme.palette.getContrastText(All.purple[500]),
    backgroundColor: All.purple[500],
    '&:hover': {
      backgroundColor: All.purple[700],
    },
  }));

const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };


  const BorderLinearProgress = All.styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  
const ProjectIcon = () => (
    <img src={All.ProjectIcon1} style={{width: 15}}/>
);

function Profile(props) {
    // const value = 4;

    const [value, setValue] = All.React.useState('');
    const [total, setTotal] = All.React.useState(0);

    const [newStar, setNewStar] = All.React.useState(0);

    const [bintang1, setBintang1] = All.React.useState(0);
    const [bintang2, setBintang2] = All.React.useState(0);
    const [bintang3, setBintang3] = All.React.useState(0);
    const [bintang4, setBintang4] = All.React.useState(0);
    const [bintang5, setBintang5] = All.React.useState(0);
    
    const [persen1, setpersen1] = All.React.useState(0);
    const [persen2, setpersen2] = All.React.useState(0);
    const [persen3, setpersen3] = All.React.useState(0);
    const [persen4, setpersen4] = All.React.useState(0);
    const [persen5, setpersen5] = All.React.useState(0);

    const [rating, setRating] = All.React.useState([]);
    const ratingCollectionRef = collection(db, "Rating");

    const [autoRefresh, setAutoRefresh] = All.React.useState(true);
    const [refreshValue, setRefreshValue] = All.React.useState(true);
    const [openModal, setOpenModal] = All.React.useState(false);
    const [alert, setAlert] = All.React.useState(false);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: 5
      };

    All.React.useEffect(() => {
        let i1 = 0, i2 = 0, i3 = 0, i4 = 0, i5 = 0, total2 = 0;
        const getRating = async () => {
            const data = await getDocs(ratingCollectionRef);
            setRating(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
            data.forEach((doc) => {
                // console.log(doc.id, '=>', doc.data());
                switch (doc.data().bintang) {
                    case 1 :
                        setBintang1(prev => prev + 1);
                        setTotal(prev => prev + 1);
                        total2++;
                        i1++
                        // console.log("promise1", doc.data().bintang);
                    case 2 :
                        setBintang2(prev => prev + 1);
                        setTotal(prev => prev + 1);
                        total2++;
                        i2++;
                        // console.log("promise2", doc.data().bintang);
                    case 3 :
                        setBintang3(prev => prev + 1);
                        setTotal(prev => prev + 1);
                        total2++;
                        i3++;
                        // console.log("promise3", doc.data().bintang);
                    case 4 :
                        setBintang4(prev => prev + 1);
                        setTotal(prev => prev + 1);
                        i4++;
                        total2++;
                        // console.log("promise4", doc.data().bintang);
                    case 5 :
                        setBintang5(prev => prev + 1);
                        setTotal(prev => prev + 1);
                        i5++;
                        total2++;
                        // console.log("promise5", doc.data().bintang);
                        // console.log(i5);
                }
            });
            getBintang();
        };
        const getBintang = () => {
            var temp5 = (i5 / total2) * 100;
            var temp4 = (i4 / total2) * 100;
            var temp3 = (i3 / total2) * 100;
            var temp2 = (i2 / total2) * 100;
            var temp1 = (i1 / total2) * 100;
            // console.log(i5);

            if (temp5 === 0) {
                setpersen5(temp5.toFixed(3))
            } else if (temp5 < 100) {
                setpersen5(temp5.toFixed(2))
            } else {
                setpersen5(temp5)
            }

            if (temp4 === 0) {
                setpersen4(temp4.toFixed(3))
            } else if (temp4 < 100) {
                setpersen4(temp4.toFixed(2))
            } else {
                setpersen4(temp4)
            }

            if (temp3 === 0) {
                setpersen3(temp3.toFixed(3))
            } else if (temp3 < 100) {
                setpersen3(temp3.toFixed(2))
            } else {
                setpersen3(temp3)
            }

            if (temp2 === 0) {
                setpersen2(temp2.toFixed(3))
            } else if (temp2 < 100) {
                setpersen2(temp2.toFixed(2))
            } else {
                setpersen2(temp2)
            }

            if (temp1 === 0) {
                setpersen1(temp1.toFixed(3))
            } else if (temp1 < 100) {
                setpersen1(temp1.toFixed(2))
            } else {
                setpersen1(temp1)
            }
            getValue(temp5);

        }
        const getValue = (props) => {
            if(refreshValue) {
                if(props < 25) {
                    setValue("Poor");
                    // console.log(props);
                } else if (props < 50) {
                    setValue("Ok");
                    // console.log(props);
                } else if (props < 75) {
                    setValue("Good");
                    // console.log(props);
                } else {
                    setValue("Excellent");
                    // console.log(props);
                } 
                
                setRefreshValue(false);
            } 
        }

        if(autoRefresh) {
            getRating();
            setAutoRefresh(false);
        } 

    }, [autoRefresh]);

    All.React.useEffect (() => {
        if(alert) {
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        }
    }, [alert]);

    const createRating = async (props) => {
        await addDoc(ratingCollectionRef, { bintang: Number(props) });
        setAutoRefresh(true);
        // console.log("new value", Number(props));
    };
    

  return (
    <div className="container-1 fade-in">
        <All.Container maxWidth="md">
            <All.Box sx={{ maxWidth: '300px', position: 'absolute', top: '0',
             marginTop: 1}}>
                <All.Collapse in={alert}>
                    <All.Alert
                    action={
                        <All.IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setAlert(false);
                        }}
                        >
                        <All.CloseIcon fontSize="inherit" />
                        </All.IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    Rating success! thanks.
                    </All.Alert>
                </All.Collapse>
            </All.Box>
            <tesBintang bintang="tes" />
            <All.Grid  container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <All.Grid item xs={12}>
                    <div className="greet-profile">
                    <h1>HI
                        <span style={{color: '#FC5957'}}>.</span> 
                    </h1>
                    </div>
                </All.Grid>
                <All.Grid item md={8} data-aos="zoom-in">
                    <div className="text-profile">
                        <p style={{textAlign: 'justify'}}>
                            I am Aries Firmansyah, currently working as a front-end web developer 
                            and also an active student at Multimedia Nusantara University. Recently, I learned 
                            a website framework like Laravel, React, and others. Learning to build a website 
                            in my opinion is like fishing for a fish. We will wait a long time to get the fish, 
                            but after getting the fish, it feels good. Likewise with learning to build a website 
                            which is sometimes difficult, but when we succeed in creating the website we want, 
                            we certainly make fun for ourselves. 
                        </p>

                        <All.Link href="https://www.instagram.com/ariesfirmansyah_/" target="_blank"
                            style={{color: 'inherit', cursor: 'pointer'}}>
                            <All.TiSocialInstagram style={{fontSize: 30}}/>
                        </All.Link>
                        <All.Link href="https://www.linkedin.com/in/aris-firmansyah-77405b17b/" target="_blank"
                            style={{color: 'inherit', cursor: 'pointer'}}>
                            <All.FaLinkedin  style={{fontSize: 25, marginBottom: 2}} />
                        </All.Link>
                        <All.Link href="https://github.com/AriesFirmansyah" target="_blank"
                            style={{color: 'inherit', cursor: 'pointer'}}>
                            <All.FaGithub style={{fontSize: 25, marginBottom: 2}} />
                        </All.Link>
                        <All.Link href="mailto:ariesfirmansyah678@gmail.com" target="_blank"
                            style={{color: 'inherit', cursor: 'pointer'}}>
                            <All.MdOutlineMail style={{fontSize: 31, marginBottom: -1}} />
                        </All.Link>
                    </div>
                </All.Grid>
                <All.Grid item md={4} >
                    <img src={All.fotoProfile} className="image-profile" data-aos="flip-down" />
                </All.Grid>
    
            </All.Grid>
            <br></br>
            <All.Grid container rowSpacing={1} gap={5}>
                <All.Grid md={5} xs={12} style={{backgroundColor: ''}}>
                    <h2 className="rating-text">Rating</h2>
                    <div className="rating-container1">
                        <All.Rating
                            name="text-feedback"
                            value={persen5}
                            readOnly
                            precision={0.5}
                            emptyIcon={<All.StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <h3>{value}</h3>
                    </div>
                    <div>
                        <div className="rating-container2">
                            <h4>5</h4>
                            <All.StarIcon style={{ paddingRight: 10 }} fontSize="inherit" />
                            <All.Box sx={{ flexGrow: 1 }} style={{maxWidth: 200}}>
                                <BorderLinearProgress variant="determinate" value={persen5} />
                            </All.Box>
                            <p style={{ paddingLeft: 10}}>{persen5}%</p>
                        </div>
                        <div className="rating-container2 margin-rating">
                            <h4>4</h4>
                            <All.StarIcon style={{ paddingRight: 10 }} fontSize="inherit" />
                            <All.Box sx={{ flexGrow: 1 }}  style={{maxWidth: 200}}>
                                <BorderLinearProgress variant="determinate" value={50} />
                            </All.Box>
                            <p style={{ paddingLeft: 10}}>{persen4}%</p>
                        </div>
                        <div className="rating-container2 margin-rating" style={{ display: 'flex', alignItems: 'center', marginTop: -44 }}>
                            <h4>3</h4>
                            <All.StarIcon style={{ paddingRight: 10 }} fontSize="inherit" />
                            <All.Box sx={{ flexGrow: 1 }} style={{maxWidth: 200}}>
                                <BorderLinearProgress variant="determinate" value={50} />
                            </All.Box>
                            <p style={{ paddingLeft: 10}}>{persen3}%</p>
                        </div>
                        <div className="rating-container2 margin-rating">
                            <h4>2</h4>
                            <All.StarIcon style={{ paddingRight: 10 }} fontSize="inherit" />
                            <All.Box sx={{ flexGrow: 1 }} style={{maxWidth: 200}}>
                                <BorderLinearProgress variant="determinate" value={50} />
                            </All.Box>
                            <p style={{ paddingLeft: 10 }}>{persen2}%</p>
                        </div>
                        <div className="rating-container2 margin-rating">
                            <h4>1</h4>
                            <All.StarIcon style={{ paddingRight: 10 }} fontSize="inherit" />
                            <All.Box sx={{ flexGrow: 1 }} style={{maxWidth: 200}}>
                                <BorderLinearProgress variant="determinate" value={50} />
                            </All.Box>
                            <p style={{ paddingLeft: 10}}>{persen1}%</p>
                        </div>
                        <div
                            className={sessionStorage.getItem('rating') === "ok" ? "hide" 
                            : "rating-container2 margin-rating"} 
                            style={{marginTop: 0}}>
                            <All.Button onClick={handleOpen} sx={{ borderRadius: 2}} variant="contained" size="small" color="secondary" startIcon={<ProjectIcon />} style={{marginLeft: 2}}>
                                Click to Rate
                            </All.Button>

                            
                        </div>
                    </div>
                </All.Grid>
                <All.Grid md={6} xs={12}>
                    <div className='fade-in'>
                        <h2 className="newest-projectText">Newest Projects</h2>
                        <h4 style={{textAlign: 'center'}}>MovieDB (MERN Stack)</h4>
                        <div className="rating-container2">
                            <img src={All.R4} style={{maxWidth: 200}}/>
                        </div>
                        <div className="newest-projectButton" style={{textAlign: '', marginTop: 10}}>
                            <All.Link href="https://ariesfirmansyah-risdevmovie.vercel.app/" target="_blank" style={{textDecoration: 'none'}}>
                                <All.Button sx={{ borderRadius: 3 }}variant="contained" size="small" startIcon={<All.LaunchIcon />} >Visit</All.Button>
                            </All.Link>
                            <All.Link href="/projects" style={{textDecoration: 'none'}}>
                                <All.Button sx={{ borderRadius: 3 }} variant="contained" size="small" color="secondary" startIcon={<ProjectIcon />} style={{marginLeft: 2}}>
                                    See more..
                                </All.Button>
                            </All.Link>
                        </div>
                    </div>
                    
                </All.Grid>
            </All.Grid>
            
            <All.Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <All.Box sx={style}>
                    <div>
                        <h3 style={{textAlign: 'center', marginTop: -20}}>
                            Rating
                        </h3>
                        <div style={{marginTop: -5}}>
                            <All.Rating
                                name="simple-controlled"
                                value={newStar}
                                onChange={(event, newValue) => {
                                    setNewStar(newValue); setOpenModal(false);
                                    setAlert(true); sessionStorage.setItem('rating', "ok");
                                    createRating(newValue);
                                }}
                                />
                        </div>
                    </div>
                </All.Box>
            </All.Modal>
    </All.Container>
    </div>
  );
}

export default Profile;
