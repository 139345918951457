import './css/Experiences.css';
import * as All from './importList';

function Experiences() {
    const [theme, setTheme] = All.React.useState('');

    const handleObscura = () => {
        sessionStorage.setItem('obscura', true);
    };
    const handleMentoring = () => {
        sessionStorage.setItem('mentoring', true);
    };
    
    All.React.useEffect(() => {
        setTheme(sessionStorage.getItem('mode'));
    });
  return (
    <div style={{
        backgroundColor: '',
        marginTop: 30,
        marginBottom: 40,
        }} className="fade-in">
        <All.Container maxWidth="md">
            <h1 data-aos="fade-right">My Experiences</h1>
            <p style={{textAlign: 'justify'}} data-aos="fade-up">
                Experience is an important thing in my life. 
                The process of forming knowledge involves experiences that occur. 
                Here are some work experiences that I have gone through. 
            </p>
            <All.Container maxWidth="sm" style={{ paddingBottom: 130, marginTop: 30}}>
                <div className="fade-in">
                    <All.Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                    style={{paddingBottom: 20}} className="border">
                        <All.Grid item md={5} xs={12} style={{backgroundColor: ''}}> 
                            <img src={All.E3} className="image-experience"
                                style={{height: "80px"}} />
                        </All.Grid>
                        <All.Grid item md={7} xs={12} style={{marginTop: -0}}> 
                            <div className="text-center">
                                <p style={{marginBottom: -20}}>Januari - Juli 2022</p>
                                <h3>Phintraco Consulting</h3>
                                <p style={{marginTop: -20, color: theme === "true"  ? '#A5A3A3' : '#585858'}}>
                                    Front-End Web Developer
                                </p>
                                <All.Button 
                                    href="https://my.telkomsel.com/" 
                                    target="_blank" 
                                    variant="contained" 
                                    size="small" s
                                    tartIcon={<All.LaunchIcon />}>
                                        See Project
                                </All.Button>
                            </div>
                        </All.Grid>
                    </All.Grid>
                </div>
                <div className="fade-in">
                    <All.Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                    style={{paddingBottom: 20}} className="border">
                        <All.Grid item md={5} xs={12} style={{backgroundColor: ''}}> 
                            <img src={All.E2} className="image-experience" />
                        </All.Grid>
                        <All.Grid item md={7} xs={12} style={{marginTop: -0}}> 
                            <div className="text-center">
                                <p style={{marginBottom: -20}}>Agustus - Oktober 2021</p>
                                <h3>UMN Mentoring 2021</h3>
                                <p style={{marginTop: -20, color: theme === "true"  ? '#A5A3A3' : '#585858'}}>
                                    Web Developer
                                </p>
                                <All.Button onClick={handleMentoring} href="/projects" variant="contained" size="small" startIcon={<All.LaunchIcon />}>
                                    See Project
                                </All.Button>
                            </div>
                        </All.Grid>
                    </All.Grid>
                </div>
                <div className="fade-in">
                    <All.Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                    style={{paddingBottom: 20}} className="border">
                        <All.Grid item md={5} xs={12} style={{backgroundColor: ''}}> 
                            <img src={All.E2} className="image-experience" />
                        </All.Grid>
                        <All.Grid item md={7} xs={12} style={{marginTop: -0}}> 
                            <div className="text-center">
                                <p style={{marginBottom: -20}}>Jan - Agustus 2021</p>
                                <h3>UMN Obscura 2021</h3>
                                <p style={{marginTop: -20, color: theme === "true"  ? '#A5A3A3' : '#585858'}}>
                                    Web Developer
                                </p>
                                <All.Button onClick={handleObscura} href="/projects" variant="contained" size="small" startIcon={<All.LaunchIcon />}>
                                    See Project
                                </All.Button>
                            </div>
                        </All.Grid>
                    </All.Grid>
                </div>
                <div className="fade-in">
                    <All.Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
                    style={{paddingBottom: 20}} className="border">
                        <All.Grid item md={5} xs={12} style={{backgroundColor: ''}}> 
                            <img src={All.E2} className="image-experience" />
                        </All.Grid>
                        <All.Grid item md={7} xs={12} style={{marginTop: -0}}> 
                            <div className="text-center">
                                <p style={{marginBottom: -20}}>Jan - Juli 2021</p>
                                <h3>Aslab Introduction to Multimedia Technology (UMN)</h3>
                                <p style={{marginTop: -20, color: theme === "true"  ? '#A5A3A3' : '#585858'}}>
                                    Part-Time
                                </p>
                                <All.Button href="https://drive.google.com/file/d/125J3U7M9iYrE0975GRLnUYKI3X8KJe0L/view?usp=sharing" 
                                target="_blank" variant="contained" size="small" startIcon={<All.LaunchIcon />}>
                                    See Credential
                                </All.Button>
                            </div>
                        </All.Grid>
                    </All.Grid>
                </div>
                <div className="fade-in">
                    <All.Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                    style={{paddingBottom: 20}} className="border">
                        <All.Grid item md={5} xs={12} style={{backgroundColor: ''}}> 
                            <img src={All.E1} className="image-experience1"/>
                        </All.Grid>
                        <All.Grid item md={7} xs={12} style={{marginTop: -0}}> 
                            <div className="text-center">
                                <p style={{marginBottom: -20}}>Feb - Mei 2018</p>
                                <h3>Saint John's Catholic School</h3>
                                <p style={{marginTop: -20, color: theme === "true"  ? '#A5A3A3' : '#585858'}}>
                                    IT Support (Intern)</p>
                                <All.Button href="https://drive.google.com/file/d/1jYBl2eCtXpQt9Lt4esCO8-ts_ctGRGD7/view?usp=sharing" target="_blank"
                                 variant="contained" size="small" startIcon={<All.LaunchIcon />}>
                                     See Credential
                                     </All.Button>
                            </div>
                        </All.Grid>
                    </All.Grid>
                </div>
                
            </All.Container>
        </All.Container>
    </div>
  );
}

export default Experiences;
